import React, { Component } from 'react';
import { HotTable } from '@handsontable/react';
import { Message } from 'semantic-ui-react';
import hotTableUtils from 'common/ui/hotTableUtils';
import { ipoTradeGridColumns } from './GridColumnMap';
import { Modal, Button, Spin } from 'antd';
import { DIALOG_EXECUTE_IPO_TRADES } from '../../orderConstants';
import Trade from '../../entities/Trade';
import _ from 'lodash';
import hyperid from 'hyperid';
import client from '../../api/client';
import TradeValidator from '../../entities/validators/TradeValidator';
import { roleTradeLabel } from 'common/utils/DomainUtils';

const uniqidInstance = hyperid();

class IPOTradesDialog extends Component {
  constructor(props) {
    super(props);
    this.rowKey = 0;
    const { liveRiskInfoMap } = this.props;
    const { funds } = this.props.settings;
    const fundBookOptions = [];
    funds.forEach(fundItem => {
      const books = fundItem.books
        ? fundItem.books.filter(r => r.enableTxn)
        : [];
      if (!_.isEmpty(books)) {
        let fundBook = {};
        fundBook.value = fundItem.name;
        fundItem.books.forEach(item => {
          if (item.enableTxn)
            fundBookOptions.push(fundItem.name + '-' + item.name);
        });
      }
    });
    const hideFundBook =
      !funds ||
      !funds.length ||
      (funds.length === 1 && funds[0].books.length <= 1);
    this.state = {
      isLoading: true,
      fundBookOptions: fundBookOptions,
      hideFundBook: hideFundBook,
      submitStatus: 'READY',
      serverErrMsg: '',
      verificationErrs: {},
      selectedIPOTrade: null,
      approvedIPOList: [],
      ipoTrades: [],
      securityMap: {},
      hotTableSettings: null,
      liveRiskInfoMap: liveRiskInfoMap,
      gridWrapperStyle: {
        width: '100%',
        height: '500px',
        marginTop: '20px'
      }
    };
  }

  componentDidMount() {
    this._getIPOList();
    this._debouncedSubmit = _.debounce(this.onFormSubmit, 500);
  }

  _initIPOTrades = size => {
    const { info } = this.props;
    const updateData = [];
    if (!info.createNew) {
      const defaultFields = info.defaultFields;
      const fundBook =
        defaultFields['fundCode'] + '-' + defaultFields['bookCode'];
      const key = 1;
      const data = {
        ...defaultFields,
        fundBook,
        key
      };
      updateData.push(data);
      return updateData;
    }
    for (let i = 0; i < size; i++) {
      updateData.push(this._addTrade());
    }
    return updateData;
  };

  _getIPOList = () => {
    const approvedIPOList = [];
    client
      .getCanIPOList()
      .then(result => {
        const data = result['data'];
        if (result && data) {
          data.forEach(item => {
            approvedIPOList.push(item['issuerTicker']);
            item['ticker'] = item['issuerTicker'];
            item['name'] = item['issuerName'];
          });
          const securityMap = _.keyBy(
            data.filter(Boolean),
            s => s.issuerTicker
          );
          const ipoTrades = this._initIPOTrades(8);
          const setting = this._createIPOGridSettings(
            this.state.fundBookOptions,
            approvedIPOList
          );
          this.setState({
            approvedIPOList: approvedIPOList,
            hotTableSettings: setting,
            securityMap: securityMap,
            ipoTrades: ipoTrades,
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false
          });
        }
      })
      .catch(_ => {
        this.setState({
          isLoading: false
        });
      });
  };

  _createIPOGridSettings = (fundBookOptions, approvedIPOList) => {
    const { info } = this.props;
    const optionsMapping = {
      fundBook: fundBookOptions,
      ticker: approvedIPOList
    };
    const columns = ipoTradeGridColumns.map(c => {
      const values = optionsMapping[c.data];
      return values
        ? {
            ...c,
            source: values
          }
        : c;
    });
    if (info.createNew) {
      return hotTableUtils.createSettings({
        columns: columns,
        rowHeaders: true,
        contextMenu: {
          items: {
            clone: {
              name: 'Clone row',
              callback: () => {
                // Must delay below operation, otherwise handsontable will throw exception.
                _.delay(() => this._cloneIPOTrade(), 100);
              }
            },
            remove_row: {}
          }
        }
      });
    } else {
      const updateColumns = columns.map(item => {
        let readOnly = false;
        if (
          item['headerName'] === 'Fund-Book' ||
          item['headerName'] === 'Ticker'
        ) {
          readOnly = true;
        }
        return {
          ...item,
          readOnly
        };
      });
      return hotTableUtils.createSettings({
        columns: updateColumns,
        rowHeaders: true
      });
    }
  };

  _cloneIPOTrade = () => {
    const { ipoTrades, selectedIPOTrade } = this.state;
    const row = ipoTrades.findIndex(r => r.key === selectedIPOTrade.key);
    const key = `N_${uniqidInstance()}`;
    const cloneRow = {
      ...selectedIPOTrade,
      key
    };
    const updatedIpoList = [
      ...ipoTrades.slice(0, row + 1),
      cloneRow,
      ...ipoTrades.slice(row + 1)
    ];
    this.setState({ ipoTrades: updatedIpoList });
  };

  _createIPOTradesGrid = () => {
    const { gridWrapperStyle, hotTableSettings, ipoTrades } = this.state;
    return (
      !_.isEmpty(ipoTrades) && (
        <div style={gridWrapperStyle}>
          <HotTable
            data={ipoTrades}
            beforeChange={this._beforeCellChange}
            manualColumnResize={true}
            afterSelectionEnd={this._onRowSelectionChanged}
            {...hotTableSettings}
          ></HotTable>
        </div>
      )
    );
  };

  onFormSubmit = () => {
    const { securityMap, ipoTrades } = this.state;
    const trades = ipoTrades.filter(r => r.updatedFlag);

    if (_.isEmpty(trades)) {
      this.closeDialog();
      return;
    }

    const verificationErrs = this._validateTrades(trades);
    const hasErrors = Object.values(verificationErrs).some(tradeErrors =>
      Object.values(tradeErrors).some(Boolean)
    );
    if (hasErrors) {
      this.setState({ verificationErrs });
      return;
    }

    const tradeModels = trades
      .filter(r => r.fundBook)
      .map(t => {
        const qtyInput = t.qtyInput === 'ByPosPct' ? 'ByPct' : t.qtyInput;
        const fundBook = t.fundBook;
        const fundBookArr = fundBook.split('-');
        const mergedFields = {
          ...t,
          fundCode: fundBookArr[0],
          bookCode: fundBookArr[1],
          qtyInput
        };

        return Trade.toModel(mergedFields, securityMap[t.ticker], false);
      });

    const { submitDialogSuccess, info } = this.props;
    const trade = tradeModels[0];
    this.setState({ submitStatus: 'SUBMITTING' });
    if (info.createNew) {
      client
        .addTrades(tradeModels)
        .then(result => {
          if (info.createNew) {
            _.zip(tradeModels, result).forEach(([t, r]) => {
              t.tradeId = r.id;
              t.refId = r.refId;
            });
          }

          submitDialogSuccess(DIALOG_EXECUTE_IPO_TRADES, {
            ipoTrades: tradeModels,
            createNew: info.createNew
          });
          this.setState({ submitStatus: 'READY' });
        })
        .catch(_ => {
          this.setState({
            submitStatus: 'ERROR',
            serverErrMsg: 'Server Error! Please contact system administrator.'
          });
        });
    } else {
      client
        .submitTrade(trade)
        .then(result => {
          if (info.createNew) {
            trade.tradeId = result.id;
            trade.refId = result.refId;
          }
          submitDialogSuccess(DIALOG_EXECUTE_IPO_TRADES, {
            trade,
            createNew: info.createNew
          });
          this.setState({ submitStatus: 'READY' });
        })
        .catch(_ => {
          this.setState({
            submitStatus: 'ERROR',
            serverErrMsg: 'Server Error! Please contact system administrator.'
          });
        });
    }
  };

  _beforeCellChange = (changes, source) => {
    const { verificationErrs, securityMap } = this.state;
    let { ipoTrades } = this.state;
    let trades = ipoTrades;
    const updatedTrades = changes
      .map(c => {
        const [row, field, oldValue, newValue] = c;
        if (oldValue === newValue) return null;
        const trade = ipoTrades[row];
        if (field === 'fundBook' && newValue) {
          const fundBookArr = newValue.split('-');
          trade['fundCode'] = fundBookArr[0];
          trade['bookCode'] = fundBookArr[1];
        }
        return this._calcTrade(trade, field, newValue, securityMap);
      })
      .filter(Boolean);

    const updatedTradeMap = _.keyBy(updatedTrades, ut => ut.key);
    trades = trades.map(t => {
      const ut = updatedTradeMap[t.key];
      return ut || t;
    });

    const errorsByKey = this._validateTrades(updatedTrades);

    this.setState({
      ipoTrades: trades,
      verificationErrs: {
        ...verificationErrs,
        ...errorsByKey
      }
    });

    return false;
  };

  _aggregateTradeErrors = (prev, key, errors) => {
    return Object.entries(errors)
      .filter(([_, err]) => err)
      .reduce((acc, [_, err]) => {
        const keys = acc[err.msg] || [];
        return {
          ...acc,
          [err.msg]: [...keys, key]
        };
      }, prev);
  };

  _calcTrade = (trade, name, value, securityMap) => {
    const { liveRiskInfoMap } = this.state;

    if (trade && trade['fundCode'] && trade['bookCode']) {
      let extraFields = {};
      if (name !== 'qtyPct' && (name === 'qtyUsd' || name === 'fundBook')) {
        let qtyUsd = 'qtyUsd' === name ? value : trade['qtyUsd'];
        extraFields = this._calAum(trade, qtyUsd, liveRiskInfoMap.byKey);
      }
      if (name !== 'qtyUsd' && (name === 'qtyPct' || name === 'fundBook')) {
        let qtyPct = 'qtyPct' === name ? value : trade['qtyPct'];
        extraFields = this._calUsdByPct(trade, qtyPct, liveRiskInfoMap.byKey);
      }
      let securityCode =
        'ticker' === name ? securityMap[value]['name'] : trade['securityCode'];
      return {
        updatedFlag: true,
        ...trade,
        securityCode,
        [name]: value,
        ...extraFields
      };
    }
    return {
      updatedFlag: true,
      ...trade,
      [name]: value
    };
  };

  _calAum = (trade, qtyUsd, riskInfoMap) => {
    const { fundCode, bookCode } = trade;
    const { fundBooks } = this.props.settings;

    const riskKey = `${fundCode}-${bookCode}`;
    const masterKey = _.get(fundBooks, [riskKey, 'masterFundBook']);
    const risk = riskInfoMap[riskKey] || riskInfoMap[masterKey];
    if (risk) {
      const { usdNav } = risk;
      const qtyPct = this._calcPctByQtyUsd(qtyUsd, usdNav);
      return {
        qtyPct
      };
    }
    return {};
  };

  _calUsdByPct = (trade, qtyPct, riskInfoMap) => {
    const { fundCode, bookCode } = trade;
    const { fundBooks } = this.props.settings;
    const riskKey = `${fundCode}-${bookCode}`;
    const masterKey = _.get(fundBooks, [riskKey, 'masterFundBook']);
    const risk = riskInfoMap[riskKey] || riskInfoMap[masterKey];
    if (risk) {
      const { usdNav } = risk;
      const qtyUsd = usdNav ? _.round((qtyPct / 100.0) * usdNav) : 0;
      return {
        qtyUsd
      };
    }
    return {};
  };

  _calcPctByQtyUsd = (qtyUsd, usdNav) => {
    return usdNav ? _.round(parseFloat((qtyUsd * 100) / usdNav), 3) : 0;
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  _addTrade = () => {
    const newRecord = Trade.emptyForm('IPO');
    const key = ++this.rowKey;
    newRecord['key'] = key;
    newRecord['pmReason'] = 'IPO';
    newRecord['qtyInput'] = 'ByUsd';
    return newRecord;
  };

  _addTradeByButton = () => {
    const { ipoTrades } = this.state;
    const updatedIpoList = [...ipoTrades, this._addTrade()];
    this.setState({ ipoTrades: updatedIpoList });
  };

  _createOperationBar = () => {
    const { approvedIPOList, isLoading } = this.state;
    const { info } = this.props;
    if (isLoading || !info.createNew) {
      return;
    }
    const { user } = this.props.settings;
    let visible = user['role'] === 'Admin' || user['role'] === 'Trader';
    visible = visible ? 'flex' : 'none';
    return _.isEmpty(approvedIPOList) ? (
      <div style={{ textAlign: 'center' }}>No IPO security could be traded</div>
    ) : (
      <div className="orderDialogOperationBar" style={{ display: visible }}>
        <div className="rightOperations">
          <Button type="primary" shape="round" onClick={this._addTradeByButton}>
            Add
          </Button>
        </div>
      </div>
    );
  };

  _validateTrades = trades => {
    return _.fromPairs(
      trades.map(t => {
        const errors = TradeValidator.validateIPOTrades(t);
        return [t.key, errors];
      })
    );
  };

  _createErrorsPanel = () => {
    const { serverErrMsg, verificationErrs } = this.state;
    const errorsByMsg = Object.entries(verificationErrs).reduce(
      (acc, [key, errors]) => {
        return this._aggregateTradeErrors(acc, key, errors);
      },
      {}
    );

    const verificationErrMsgs = Object.entries(errorsByMsg).map(
      ([msg, keys]) => {
        return keys.length > 0 ? `[${keys.join(',')}]: ${msg}` : `${msg}`;
      }
    );

    const errorMsgs = [...verificationErrMsgs, serverErrMsg].filter(Boolean);
    return (
      errorMsgs.length > 0 && (
        <Message error header="Fix Below Errors" list={errorMsgs} />
      )
    );
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_EXECUTE_IPO_TRADES);
  };

  _onRowSelectionChanged = row => {
    const { ipoTrades, selectedIPOTrade } = this.state;
    const ipoTradeRow = ipoTrades[row];
    if (ipoTradeRow === selectedIPOTrade) return;
    this.setState({
      selectedIPOTrade: ipoTradeRow
    });
  };

  render() {
    const { isLoading } = this.state;
    const {
      info,
      settings: { user = {} }
    } = this.props;
    let title = info.createNew
      ? `New IPO ${roleTradeLabel(user)}`
      : `Update IPO ${roleTradeLabel(user)}`;
    return (
      <Modal
        width={1200}
        maskClosable={false}
        title={title}
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._debouncedSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <Spin tip="Loading..." spinning={isLoading}>
          {this._createOperationBar()}
          {this._createIPOTradesGrid()}
          {this._createErrorsPanel()}
        </Spin>
      </Modal>
    );
  }
}

export default IPOTradesDialog;
